<template>
    <div v-if="!wspLoader" class="worckspace__body">
        <page-header title='Типы упаковки' icon="box" :navlinks="navlinks" />
        <router-view></router-view>
        <table class="control-table">
            <thead>
                <tr>
                    <th>ID</th>
                    <th>Тип Упаковки</th>
                </tr>
            </thead>
            <tr v-for="type in PackTypes" :key="type.id">
                <td>
                    <router-link :to="'/packs/types/' + type.id">
                    {{ type.id }}
                    </router-link>
                </td>
                <td>
                    <router-link :to="'/packs/types/' + type.id">
                    {{ type.name }}
                    </router-link>
                </td>
            </tr>
        </table>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
export default {
    name: 'PacksTypes',
    data(){
        return {
            navlinks: [
                {
                    title: 'Назад к списку упаковки',
                    route: '/packs',
                    icon: 'arrow-left'
                },
                {
                    title: 'Добавить',
                    route: '/packs/types/new',
                    icon: 'plus'
                },
            ]
        }
    },
    methods: {
        ...mapActions({
            getPackTypes: 'GetPackTypesAction'
        })
    },
    computed: {
        ...mapGetters({
            wspLoader: 'GetWspLoader',
            PackTypes: 'GetPackTypes'
        }),
    },
    created(){
        if(!this.PackTypes){
            this.getPackTypes();
        }
    }
}
</script>